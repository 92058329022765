import { AUTHORIZE } from '../actions'

const defaultState = { logged: false }

const authorization = (state = defaultState, action) => {
  switch(action.type) {
    case AUTHORIZE:
      return {
        ...state,
        logged: true
      };

    default: 
      return state;
  }
}

export default authorization