import React, { Suspense, lazy } from 'react';
import { Helmet } from 'react-helmet';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
import { Loading } from './app-styles';

const HomePage = lazy(() => import(/* webpackChunkName: "home" */'../home/home'));
const ResultPage = lazy(() => import(/* webpackChunkName: "result" */'../result/result'));
const TestPage = lazy(() => import(/* webpackChunkName: "test" */'../test/test'));

const NoMatch = () => (
    <>
      <Helmet>
        <title>Страница не найдена</title>
        <link rel="icon" href="/favicon.ico" type="image/x-icon"/>
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon"/>
        <meta name="robots" content="follow, noarchive, noindex"/>
      </Helmet>

      Страница не найдена
    </>
);

const history = createBrowserHistory()

// Посылаем события в гугл
history.listen((location) => {
  try {
    window.ga('set', 'page', location.pathname);
    window.ga('send', 'pageview');
  } catch (e) {
  }
})

export default class App extends React.Component {

  render() {
    return (
        <>
          <Helmet>
            <title>Лиотон</title>
          </Helmet>

          <Router history={history}>
            <Suspense fallback={<Loading/>}>
              <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route path="/result/:key" component={ResultPage}/>
                <Route path="/test" component={TestPage}/>
                <Route component={NoMatch}/>
              </Switch>
            </Suspense>
          </Router>
        </>
    );
  }

}