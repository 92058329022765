import React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled, { keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const LoadingWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Frutiger Neue LT W1G Regular", sans-serif;
	font-size: 30px;
	z-index: 1111;
	
	&.loading-enter {
		opacity: 0.01;
	}
	
	&.loading-enter.loading-enter-active {
		opacity: 1;
		transition: opacity 500ms ease-in;
	}
	
	&.loading-leave {
		opacity: 1;
	}
	
	&.loading-leave.loading-leave-active {
		opacity: 0.01;
		transition: opacity 300ms ease-in;
	}
	
	svg {
		opacity: .3;
		display: block;
		animation: ${rotate} 2s linear infinite;
	}
`
export const Loading = ({ onClick }) => (
	<CSSTransition in={true} timeout={300} classNames="loading">
		<LoadingWrapper onClick={onClick}>
			<svg height="100" width="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
				<path
					d="M50 5c-7.163 0-13.648.917-18.594 2.5-2.473.791-4.565 1.726-6.25 3C23.472 11.774 22 13.629 22 16v15c-.002.757.295 1.51.813 2.062L38.843 50l-16.03 16.937A3.034 3.034 0 0 0 22 69v15c0 2.371 1.472 4.226 3.156 5.5 1.685 1.274 3.777 2.209 6.25 3C36.352 94.083 42.837 95 50 95c7.163 0 13.648-.917 18.594-2.5 2.473-.791 4.565-1.726 6.25-3C76.528 88.226 78 86.371 78 84V69a3.036 3.036 0 0 0-.813-2.063L61.157 50l16.03-16.938A3.034 3.034 0 0 0 78 31V16c0-2.371-1.472-4.226-3.156-5.5-1.685-1.275-3.777-2.209-6.25-3C63.648 5.917 57.163 5 50 5zm0 6c6.644 0 12.648.875 16.75 2.187 1.916.693 3.794 1.383 5.219 2.813-1.293 1.359-3.628 2.295-5.219 2.812C62.648 20.125 56.644 21 50 21s-12.648-.875-16.75-2.188c-1.916-.693-3.794-1.382-5.219-2.812 1.293-1.359 3.628-2.295 5.219-2.813C37.352 11.875 43.356 11 50 11zM28 23.187c1.043.492 2.17.917 3.406 1.313C36.352 26.083 42.837 27 50 27c7.163 0 13.648-.917 18.594-2.5 1.236-.396 2.363-.82 3.406-1.313v6.594L54.812 47.937c-1.042 1.101-1.042 3.024 0 4.125L72 70.22v6.593c-1.043-.491-2.17-.916-3.406-1.312-3.632-1.162-8.112-1.968-13.063-2.313C53.011 71.855 50.93 70.024 50 67c-.845 2.748-2.662 4.646-4.875 6.125-5.214.318-9.926 1.161-13.719 2.375-1.236.396-2.363.82-3.406 1.312V70.22l17.187-18.157c1.043-1.1 1.043-3.024 0-4.125L28 29.781zM34 30c3 8 15 13 16 26 0-13 13-18 16-26-13 8-20 6-32 0zm-4 52.562c10.056 5.12 29.944 5.12 40 0 .792.357 1.368.835 1.969 1.438-1.293 1.359-3.628 2.295-5.219 2.812C62.648 88.125 56.644 89 50 89s-12.648-.875-16.75-2.188c-1.916-.692-3.794-1.382-5.219-2.812.522-.669 1.219-1.02 1.969-1.438z"
					color="#000"/>
			</svg>
		</LoadingWrapper>
	</CSSTransition>
)