import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux'

import './fonts/stylesheet.css';
import './index.css';

import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store'
import AppContainer from './components/app/app-container'

const store = configureStore();

const renderApp = () =>
  render(
    <Provider store={store}>
      <AppContainer />
    </Provider>,
    document.getElementById('root')
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./components/app/app-container', renderApp)
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
