import { connect } from 'react-redux';

import { authorize } from '../../redux/actions'
import App from './app';

const mapStateToProps = state => ({
  authorization: state.authorization
})

const mapDispatchToProps = dispatch => {
  return {
    onLoginClick: _ => {
      dispatch(authorize())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)